import React from "react"

// Libraries 
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
`

const StyledPublications = styled.section`
	padding: 28px 0;

	${breakpoint.medium`
		padding: 54px 0;
	`}
`

const Publications = props => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "viewpoint"}}}}}
        sort: {order: DESC, fields: date}
      ) {
        nodes {
          id
          slug
          date(formatString: "MMMM D, YYYY")
          dateGmt
          modifiedGmt
          title
          excerpt
          featuredImage {
            node {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  `)
  return (
    <StyledPublications>
      <StyledContainer>
        <div className="fullList">
          {data.allWpPost.nodes.map((post) => (
            <PostItem
              resourceType="post"
              key={post.id}
              link={"/news/" + post.slug + "/"}
              linkText="Read More"
              title={post.title}
              excerpt={post.excerpt}
              image={
                post.featuredImage
                  ? post.featuredImage.node.localFile
                  : false
              }
              publicURL={
                post.featuredImage
                  ? post.featuredImage.node.localFile.publicURL
                  : false
              }
              date={post.date}
              dateGmt={post.dateGmt}
              modifiedGmt={post.modifiedGmt}
            />
          ))}
        </div>
      </StyledContainer>
    </StyledPublications>
  )
}

export default Publications